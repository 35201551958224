import { debounce } from "perfect-debounce";
import PQueue from "p-queue";
import { ItemType, OrderBy, ItemType as SettingItemType, SettingScopesEnum } from "@simbelapp/auth-sdk";
import type { IFindOptions, IHeaderNav, IIndexHeader, IPaginationNav } from "~/utils/interfaces/table-interfaces";
import { useState } from "#imports";
import { useTeamsCookies } from "~~/composables/cookies/useTeamsCookies";
import { useSettingsStore } from "~/store/settings/settings.store";
import { useAuthStore } from "~/store/auth/auth.store";

export const usePagination = () => {
  const LIMIT = ref(20);
  const teamsCookies = useTeamsCookies();
  const settingsStore = useSettingsStore();
  const { gridDetails } = storeToRefs(settingsStore);
  const authStore = useAuthStore();

  const fetchFunction = useState<Function>("callback", () => () => {
    // eslint-disable-next-line
    console.log("Function not set");
  });

  function setFetchFunction(f: Function, limit = LIMIT.value, keepOptions = false) {
    if (!keepOptions) {
      reset();
    }
    fetchFunction.value = f;
    paginateOptions.value.limit = limit;
  }

  const paginateOptions = useState<IFindOptions>("paginate_options", () => {
    return {
      offset: 0,
      limit: LIMIT.value,
      search: null,
      filter: null,
      sorting_column: null,
      order: null,
    };
  });

  function reset() {
    paginateOptions.value.offset = 0;
    // eslint-disable-next-line
    paginateOptions.value.limit = paginateOptions.value.limit;
    paginateOptions.value.search = null;
    paginateOptions.value.filter = null;
    paginateOptions.value.sorting_column = null;
    paginateOptions.value.order = null;
    // todo: remove cookies filters
    // teamsCookies.clearQueryOpt()
  }

  function resetOffset() {
    paginateOptions.value.offset = 0;
  }

  async function fetchData() {
    await fetchFunction.value();
  }

  const queue = new PQueue({ concurrency: 1 });
  const pageNavigate = debounce((val: IPaginationNav, cookie?: string | null) => {
    queue.clear();
    queue.add(async () => {
      paginateOptions.value.limit = val.limit ?? 20;
      paginateOptions.value.offset = val.offset;
      if (!cookie) {
        gridDetails.value.paginationValues[0] = {
          limit: val.limit ?? 20,
          offset: val.offset ?? 0,
        };
        if (authStore.user?.user_id) {
          await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
        }
      } else {
        await teamsCookies.setQueryOpts("offset", val.offset, cookie);
        await teamsCookies.setQueryOpts("limit", val.limit, cookie);
      }
      await fetchFunction.value();
    });
  }, 200);

  const sortColumn = debounce((val: IIndexHeader, cookie?: string | null) => {
    queue.clear();
    queue.add(async () => {
      paginateOptions.value.sorting_column = val.key;
      paginateOptions.value.order = gridDetails.value.sortingValues[0]?.order ?? val.sort_order;
      if (!cookie) {
        if (val.key && gridDetails.value.sortingValues[0]?.order) {
          gridDetails.value.sortingValues[0] = {
            sortingColumn: val.key,
            scope: val.scope as SettingScopesEnum,
            order: gridDetails.value.sortingValues[0].order as OrderBy,
          };
          if (authStore.user?.user_id) {
            await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
          }
        }
      } else {
        await teamsCookies.setQueryOpts("sorting_column", val.key, cookie);
        await teamsCookies.setQueryOpts("order", val.sort_order, cookie);
      }
      await fetchFunction.value();
    });
  }, 200);

  const tabNavigate = debounce((val: IHeaderNav, cookie?: string | null) => {
    queue.clear();
    queue.add(async () => {
      paginateOptions.value.offset = 0;
      paginateOptions.value.filter = val.filter;
      gridDetails.value.paginationValues[0] = {
        limit: paginateOptions.value.limit ?? 20,
        offset: 0,
      };
      if (!cookie) {
        if (val?.filter) {
          gridDetails.value.sectionValues[0] = val.type
            ? {
                sectionName: val.filter,
                itemType: val.type as ItemType,
              }
            : {
                sectionName: val.filter,
              };
          if (authStore.user?.user_id) {
            gridDetails.value.paginationValues[0] = {
              limit: paginateOptions.value.limit ?? 20,
              offset: 0,
            };
            await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
            await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
          }
        }
      } else {
        await teamsCookies.setQueryOpts("filter", val.filter, cookie);
        await teamsCookies.setQueryOpts("offset", 0, cookie);
      }
      await fetchFunction.value();
    });
  }, 200);

  const searchNavigate = debounce(
    (val: string, cookie?: string | null) => {
      queue.clear();
      queue.add(async () => {
        paginateOptions.value.search = val;
        paginateOptions.value.offset = 0;
        if (!cookie) {
          gridDetails.value.searchValues[0] = {
            stringToSearch: val,
          };
          if (authStore.user?.user_id) {
            await settingsStore.updateGridSettings(authStore.user?.user_id, SettingItemType.User);
          }
        } else {
          teamsCookies.setQueryOpts("search", val, cookie);
          teamsCookies.setQueryOpts("offset", 0, cookie);
        }
        await fetchFunction.value();
      });
    },
    300,
    { trailing: false },
  );

  function getFindOptions(options: IFindOptions) {
    const obj: Record<string, any> = {};
    Object.entries(options).forEach(([key, val]) => {
      if (val) {
        obj[key] = val;
      }
    });
    return obj;
  }
  async function getCookiePaginateOpts(cookie?: string | null) {
    const pageOpts = await teamsCookies.getQueryOpts(cookie);
    if (pageOpts) {
      paginateOptions.value.offset = "offset" in pageOpts ? pageOpts.offset : 0;
      paginateOptions.value.limit = "limit" in pageOpts ? pageOpts.limit : 20;
      paginateOptions.value.search = "search" in pageOpts ? pageOpts.search : null;
      paginateOptions.value.filter = "filter" in pageOpts ? pageOpts.filter : null;
      paginateOptions.value.sorting_column = "sorting_column" in pageOpts ? pageOpts.sorting_column : null;
      paginateOptions.value.order = "order" in pageOpts ? pageOpts.order : null;
    }
  }

  function getPaginateOpts() {
    paginateOptions.value.offset = gridDetails.value.paginationValues[0]?.offset ?? 0;
    paginateOptions.value.limit = gridDetails.value.paginationValues[0]?.limit ?? 20;
    paginateOptions.value.search = gridDetails.value.searchValues[0]?.stringToSearch ?? null;
    paginateOptions.value.filter = gridDetails.value.sectionValues[0]?.sectionName ?? null;
    paginateOptions.value.sorting_column = gridDetails.value.sortingValues[0]?.sortingColumn ?? null;
    paginateOptions.value.order = gridDetails.value.sortingValues[0]?.order ?? null;
  }

  return {
    paginateOptions,
    setFetchFunction,
    sortColumn,
    pageNavigate,
    tabNavigate,
    fetchData,
    searchNavigate,
    getFindOptions,
    reset,
    resetOffset,
    getCookiePaginateOpts,
    getPaginateOpts,
  };
};
