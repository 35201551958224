import { SettingScopesEnum } from "@simbelapp/auth-sdk";
import type { IIndexHeader } from "~~/utils/interfaces/table-interfaces";

export const useCatalog = () => {
  const headers = useState<IIndexHeader[]>(() => [
    {
      name: "Formation",
      sort: true,
      key: "title",
      minWidthPx: 250,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Prestataire",
      sort: true,
      key: "training_partner_name",
      minWidthPx: 134,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Catégorie",
      sort: true,
      key: "categories",
      minWidthPx: 210,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Type",
      sort: true,
      key: "session_items_types",
      minWidthPx: 93,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Prix (HT)",
      sort: true,
      key: "default_price",
      minWidthPx: 80,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Format",
      sort: true,
      key: "session_items_formats",
      minWidthPx: 100,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Durée",
      sort: true,
      key: "duration_hours",
      minWidthPx: 70,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Visibilité",
      sort: true,
      key: "visible",
      minWidthPx: 85,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Source",
      sort: true,
      key: "is_company_training",
      minWidthPx: 85,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "Tag formation",
      sort: false,
      key: "training_tags",
      minWidthPx: 180,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
    {
      name: "",
      sort: false,
      key: "actions",
      minWidthPx: 60,
      maxWidthFr: 1,
      visible: true,
      scope: SettingScopesEnum.CompanyCatalogTab,
    },
  ]);

  return { headers };
};
